/* ==========================================================================
filter
========================================================================== */
(function () {
  $('.filters').children('input[type="radio"]').each((index, element) => {
    if ($(element).is(':checked')) {
      $('.' + $(element).val() + 'Menu').show();
    } else {
      $('.' + $(element).val() + '-menu').hide();
    }
  });
})();

$('.filters').children('input[type="radio"]').click(function () {
  $('.filters').children('input[type="radio"]').each((index, element) => {
    if ($(element).is(':checked')) {
      $('.' + $(element).val() + '-menu').show();
    } else {
      $('.' + $(element).val() + '-menu').hide();
    }
  });
});
