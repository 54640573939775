/* ==========================================================================
Bootstrap Select JS Files
========================================================================== */
import 'bootstrap-select';

$('.form select').selectpicker({
  style: '',
  size: 5
});

$('.form button[type=reset]').bind('click', () => {
  $('.form select').each(() => {
    $(this).val($(this).find('option:first').val()).selectpicker('refresh');
  });
});
