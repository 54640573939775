/* eslint-disable */

/* ==========================================================================
jQuery
========================================================================== */
import $ from 'jquery';

/* ==========================================================================
Animate On Scroll
========================================================================== */
import AOS from 'aos';

// Initialize AOS
AOS.init();

/* ==========================================================================
Bootstrap JS Files
========================================================================== */
import 'bootstrap';

/* ==========================================================================
Owl Carousel (must be loaded here)
========================================================================== */
import 'owl.carousel';

/* ==========================================================================
Slick Carousel - https://kenwheeler.github.io/slick/
========================================================================== */
import 'slick-carousel';

/* ==========================================================================
Bootstrap Offcanvas
========================================================================== */
import './bootstrap4/offcanvas.js';

/* ==========================================================================
Card image overlay
========================================================================== */
import './card-image-overlay/card-image-overlay.js';

/* ==========================================================================
Form
========================================================================== */
import './form/form.js';

/* ==========================================================================
LazyLoad
========================================================================== */
import './lazy_load/lazy_load.js';

/* ==========================================================================
Navbar
========================================================================== */
import './navbar/navbar.js';
import './navbar/filter.js';

/* ==========================================================================
Page List Plus
========================================================================== */
import './page_list_plus/page_list_plus.js';

/* ==========================================================================
Slider
========================================================================== */
import './slider/slider.js';

/* ==========================================================================
FAQ
========================================================================== */
import './faq/faq.js';

/* ==========================================================================
Projects selector
========================================================================== */
import './projects-selector/projects-selector.js';

/* ==========================================================================
modal
========================================================================== */
import './search-modal/search-modal.js';

/* ==========================================================================
Smoothscroll
========================================================================== */
import './smoothscroll/smoothscroll.js';

/* ==========================================================================
Video
========================================================================== */
import './video/video.js';

/* eslint-enable */
