/* ==========================================================================
Owl Carousel
========================================================================== */
$('.product-slider').owlCarousel({
  loop: false,
  margin: 10,
  nav: true,
  navText: ['<i class="fas fa-caret-left"></i>', '<i class="fas fa-caret-right"></i>'],
  dots: true,
  mouseDrag: true,
  touchDrag: true,
  items: 1,
  responsive: {
    576: {
      items: 3
    },
    768: {
      items: 4
    }
  }
});

/* ==========================================================================
Slick Carousel
========================================================================== */

$('.list-car').slick({
  arrows: false,
  asNavFor: '.letter-car',
  centerMode: true,
  centerPadding: 0,
  slidesToScroll: 1,
  slidesToShow: 1,
  swipeToSlide: true,
  variableWidth: true
});

$('.letter-car').slick({
  arrows: false,
  asNavFor: '.list-car',
  centerMode: true,
  centerPadding: 0,
  dots: false,
  focusOnSelect: true,
  slidesToScroll: 1,
  slidesToShow: 5,
  swipeToSlide: true
});
