/* ==========================================================================
Video
========================================================================== */
const YouTubeIframeLoader = require('youtube-iframe');

const initializePageVideo = function initializePageVideo () {
  YouTubeIframeLoader.load(function (YT) {
    new YT.Player('player', { // eslint-disable-line no-new
      videoId: $('#player').attr('data-video-id'),
      playerVars: {
        autoplay: 1,
        loop: 1,
        controls: 0,
        showinfo: 0,
        autohide: 1,
        modestbranding: 1,
        iv_load_policy: 3,
        vq: 'hd1080'
      },
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange
      }
    });
  });

  function onPlayerReady (event) {
    setTimeout(function () {
      $('#player').fadeIn();
    }, 1000);
    event.target.playVideo();
    event.target.mute();
  }

  function onPlayerStateChange (event) {
    if (event.target.getPlayerState() === 0) {
      event.target.playVideo();
    }
  }
};

if ($('#player').length) {
  initializePageVideo();
}

// console.log('video.js is loading');
