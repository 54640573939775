/* ==========================================================================
Card Image Overlay
========================================================================== */
$('.card-image--carousel').owlCarousel({
  loop: true,
  margin: 10,
  nav: true,
  dots: false,
  navText: ["<i class='fas fa-caret-left'></i>", "<i class='fas fa-caret-right'></i>"],
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 2
    }
  }
});
