/* ==========================================================================
Projects selector
========================================================================== */
$('.projects-selector-carousel').owlCarousel({
  loop: true,
  items: 1,
  margin: 10,
  nav: false,
  dots: true,
  navText: ["<i class='fas fa-caret-left'></i>", "<i class='fas fa-caret-right'></i>"]
});
