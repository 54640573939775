/* ==========================================================================
Bootstrap4 Offcanvas
========================================================================== */

$('[data-toggle="offcanvas"]').on('click touch', function () {
  // Toggle class for navigation button "hamburger"
  if (!$('.dropdown-menu').hasClass('show')) {
    $('.hamburger').toggleClass('is-active');
    $('.offcanvas-collapse').toggleClass('open');
  } else if ($('.dropdown-menu').hasClass('show')) {
    $('.dropdown-menu').removeClass('show');
    if (!$('.offcanvas-collapse').hasClass('open')) {
      $('.hamburger').removeClass('is-active');
    }
  }
  if ($('.hamburger').hasClass('hamburger--arrow-r')) {
    $('.hamburger').toggleClass('hamburger--spring');
    $('.hamburger').toggleClass('hamburger--arrow-r');
  }
});

// console.log('offcanvas.js is loading');

$('.dropdown .nav-link').on('click touch', function () {
  // 3th state hamburger menu
  $('.hamburger').toggleClass('hamburger--spring');
  $('.hamburger').toggleClass('hamburger--arrow-r');
});

$('.navbar--mobile-link.products').on('click touch', function () {
  if (!$('.offcanvas-collapse').hasClass('open')) {
    $('.offcanvas-collapse').addClass('open');
    $('.dropdown-menu').addClass('show');
    $('.hamburger').addClass('is-active');
  } else if ($('.offcanvas-collapse').hasClass('open')) {
    $('.offcanvas-collapse').removeClass('open');
    $('.dropdown-menu').removeClass('show');
    $('.hamburger').removeClass('is-active');
  }
});
