/* ==========================================================================
Navbar
========================================================================== */
const navbar = $('#navbar');
const navbarHeight = navbar.outerHeight();

$(window).scroll(function () {
  const scroll = $(window).scrollTop();

  if (scroll >= navbarHeight) {
    navbar.addClass('scroll');
  } else {
    navbar.removeClass('scroll');
  }
});

// console.log('navbar.js is loading');

$('.dropdown .nav-link').on('click touch', function () {
  if (!$('.dropdown-menu').hasClass('show')) {
    $('.dropdown .link-line').animate({
      width: '100%'
    });
  } else {
    $('.dropdown .link-line').animate({
      width: '25%'
    });
  }
});

$('[data-toggle="offcanvas"]').on('click touch', function () {
  if (!$('.dropdown-menu').hasClass('show')) {
    $('.dropdown .link-line').animate({
      width: '25%'
    });
  }
});
