/* ==========================================================================
LazyLoad
========================================================================== */
const LazyLoad = require('vanilla-lazyload');

const lazyLoadInstance = new LazyLoad({
  elements_selector: '.lazy'
});

if (lazyLoadInstance) {
  lazyLoadInstance.update();
}
